import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import Login from './components/auth/Login';
import LeftDrawer from './components/layout/LeftDrawer';
import CategoryList from './components/manage/categories/CategoryList';
import CategoryLanguagesList from './components/manage/languages/categories/CategoryLanguagesList';
import Languages from './components/manage/languages/Languages';
import LanguagePinList from './components/manage/languages/pins/LanguagePinList';
import EditPin from './components/manage/pins/edit/EditPin';
import PinList from './components/manage/pins/PinList';
import Tests from './components/manage/pins/Tests';
import ListUsers from './components/manage/users/ListUsers';
import AdminRoute from './components/routing/AdminRoute';
import EditPinRoute from './components/routing/EditPinRoute';
import PrivateRoute from './components/routing/PrivateRoute';
import AuthState from './context/auth/AuthState';
import { baseURL } from './utils/globals';
import setAuthToken from './utils/setAuthToken';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

axios.defaults.baseURL = baseURL;

export function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      preventDuplicate
    >
      <AuthState>
        <Router>
          <LeftDrawer />
          <Switch>
            <Route exact path="/tests" component={Tests} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={CategoryList} />
            <PrivateRoute exact path="/categories" component={CategoryList} />
            <PrivateRoute exact path="/pins" component={PinList} />
            <EditPinRoute exact path="/pins/edit" component={EditPin} />
            <PrivateRoute exact path="/languages" component={Languages} />
            <PrivateRoute
              exact
              path="/languages/pins"
              component={LanguagePinList}
            />
            <PrivateRoute
              exact
              path="/languages/categories"
              component={CategoryLanguagesList}
            />
            <AdminRoute exact path="/users" component={ListUsers} />
            {/* <Route exact path="/users" component={ListUsers} /> */}
          </Switch>
        </Router>
      </AuthState>
    </SnackbarProvider>
  );
}

export default App;
