import {
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddButton from './add/AddButton';
import AuthContext from '../../../context/auth/authContext';
import PinListItem from './PinListItem';
import axios from 'axios';
import EnhancedTableToolbar from '../../layout/EnhancedTableToolbar';
import EnhancedTableHead from '../../layout/EnhancedTableHead';
import { LatLng } from 'leaflet';
import { canIAddPin } from '../../../utils/parseJWT';

interface pinData {
  id: string;
  title: string;
  description: string;
  images: [{ id: string; title: string }];
  categories: categoryData[];
  startDate: string;
  finishDate: string;
  position: { lat: string; long: string };
}
interface categoryData {
  id: string;
  name: string;
  color: string;
}

const Pins = () => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);

  // const [pins, setPins] = useState(data);
  const [pins, setPins] = useState<pinData[]>([]);
  useEffect(() => {
    getPins();
    // eslint-disable-next-line
  }, []);

  const getPins = async () => {
    try {
      setLoading(true);
      const lang = 'pt';
      const res = await axios.get(`/pins/lang/${lang}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const newList = res.data.map((pinData, index) => {
        const {
          id,
          pinLanguages,
          images,
          categories,
          finishDate,
          startDate,
          lat,
          long,
        } = pinData;
        // console.log(categories);
        const pinCategories: categoryData[] = categories.map((category) => {
          const name = category.categoryLanguages[0].name;
          return { id: category.id, name, color: category.color };
        });

        const { title, description } = pinLanguages[0];
        return {
          title,
          id,
          description,
          images,
          categories: pinCategories,
          finishDate,
          startDate,
          position: { lat, long },
        };
      });
      setPins(newList);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleAdd(data) {
    const {
      id,
      pinLanguages,
      images,
      categories,
      finishDate,
      startDate,
      lat,
      long,
    } = data;
    const { title, description } = pinLanguages[0];
    const pinCategories: categoryData[] = categories.map((category) => {
      const name = category.categoryLanguages[0].name;
      return { id: category.id, name, color: category.color };
    });
    const position = { lat, long };
    setPins([
      ...pins,
      {
        title,
        id,
        description,
        images,
        categories: pinCategories,
        finishDate,
        startDate,
        position,
      },
    ]);
  }
  function handleRemove(id: string) {
    const newList = pins.filter((pin) => pin.id !== id);

    setPins(newList);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Container component="main" maxWidth="md">
            <EnhancedTableToolbar
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              length={pins.length}
              page={page}
              rowsPerPage={rowsPerPage}
              title="Pontos"
            />
            <TableContainer>
              {pins !== null && !loading ? (
                <Table size="small">
                  <EnhancedTableHead
                    titles={[
                      'Imagem',
                      'Titulo',
                      'Categorias',
                      'Descrição',
                      'Tipo',
                    ]}
                  />
                  <TableBody>
                    {pins
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((pin, index) => (
                        <PinListItem
                          key={pin.id}
                          pin={pin}
                          // index={index}
                          // handleCheckClick={handleCheckClick}
                          // isSelected={isSelected}
                          onRemove={handleRemove}
                          // onEdit={handleEdit}
                        />
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <CircularProgress />
              )}
            </TableContainer>
            {localStorage.token && canIAddPin(localStorage.token) ? (
              <AddButton onAdd={handleAdd} />
            ) : (
              <div />
            )}
          </Container>
        </Paper>
      </Grid>
    </Container>
  );
};

export default React.memo(Pins);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  FloatBtn: {
    marginTop: theme.spacing(4),
  },
  space: {
    margin: theme.spacing(0, 5),
  },
}));
