import axios from 'axios';

export const updatePin = async (
  setLoading,
  {
    description,
    finishDate,
    id,
    startDate,
    title,
    categoriesIds,
    lat,
    long,
    lang,
    url,
  },
  enqueueSnackbar
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.token,
    },
  };
  try {
    setLoading(true);
    // console.log(formData);
    const res = await axios.post(
      `/pins/${id}`,
      {
        description,
        finishDate,
        startDate,
        title,
        categoriesIds,
        lat,
        long,
        lang,
        url,
      },
      config
    );
    // onEdit(res.data, false);
    //success message
    enqueueSnackbar(`Campos alterados com sucesso!`, {
      variant: 'success',
      autoHideDuration: 2000,
    });
  } catch (error) {
    //error message
    const msg = error.response.data.message;

    enqueueSnackbar(msg, {
      variant: 'warning',
    });
  } finally {
    setLoading(false);
  }
};
export const uploadNewImages = async (
  setLoading,
  pinId,
  formData,
  enqueueSnackbar,
  handleNewImages
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.token,
    },
  };
  try {
    setLoading(true);
    //'/img/:pinId'
    const res = await axios.post(`/pins/img/${pinId}`, formData, config);
    handleNewImages(res.data);

    //success message
    enqueueSnackbar(`Imagens adicionadas com sucesso!`, {
      variant: 'success',
      autoHideDuration: 2000,
    });
  } catch (error) {
    //error message
    const msg = error.response.data.message;
    enqueueSnackbar(msg, {
      variant: 'warning',
    });
  } finally {
    setLoading(false);
  }
};
