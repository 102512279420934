import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Input,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Title from '../../../layout/Title';
import { useStyles } from './editPinStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import AuthContext from '../../../../context/auth/authContext';
import AddIcon from '@material-ui/icons/Add';
import { updatePin, uploadNewImages } from './submit';
import axios from 'axios';
import { MapContainer, TileLayer } from 'react-leaflet';
import LocationMarker from './../../../../utils/LocationMarker';
import { LatLng } from 'leaflet';
import SaveIcon from '@material-ui/icons/Save';
import { categoryData, pinData } from '../pinInterface';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { baseURL } from './../../../../utils/globals';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditPin = () => {
  const classes = useStyles();
  const location = useLocation();
  const [pin, setPin] = useState<pinData>({
    id: '0',
    title: '',
    description: '',
    images: [{ id: '', title: '' }],
    categories: [{ id: '', name: '', color: '' }],
    url: null,
    startDate: null,
    finishDate: null,
  });
  const [position, setPosition] = useState<LatLng>(null);

  const [isEvent, setIsEvent] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);
  const [categories, setCategories] = useState<categoryData[]>([
    {
      color: '',
      id: '0',
      name: '',
    },
  ]);
  useEffect(() => {
    setLoading(true);

    getCategories();
    getPin();
    // eslint-disable-next-line
  }, []);

  const getPin = async () => {
    try {
      const res = await axios.get(`/pins/${location.state.id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      // console.log(res.data);
      const {
        pinLanguages,
        id,
        images,
        categories,
        finishDate,
        startDate,
        url,
        lat,
        long,
      } = res.data;
      const { title, description } = pinLanguages[0];
      const pinCategories: categoryData[] = categories.map((category) => {
        const name = category.categoryLanguages[0].name;
        return { id: category.id, name, color: category.color };
      });
      setPin({
        title,
        id,
        description,
        images,
        categories: pinCategories,
        url,
        finishDate,
        startDate,
        // position: {lat, long}
      });
      const position: LatLng = new LatLng(lat, long);
      if (startDate) setIsEvent(true);
      setPosition(position);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCategories = async () => {
    try {
      const res = await axios.get('/categories/', {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const newList = res.data.map((categoryData, index) => {
        const { color, id, categoryLanguages } = categoryData;
        const name = categoryLanguages[0].name;
        return { id, name, color };
      });
      setCategories(newList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (pin.startDate && pin.finishDate) {
      setIsEvent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTypeClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEvent(event.target.checked);
  };

  const onChange = (e: { target: { name: string; value: string } }) => {
    setPin({ ...pin, [e.target.name]: e.target.value });
  };

  const handleSeclectCategoryClick = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const names = event.target.value as string[];
    const selected = categories.filter((category) =>
      names.includes(category.name)
    );
    setPin({ ...pin, categories: selected });
  };

  const handleRemoveImage = async (id: string) => {
    try {
      const lang = 'pt';
      await axios.delete(`/pins/img/${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      enqueueSnackbar(`Imagem removida`);
      const newList = pin.images.filter((image) => image.id !== id);

      setPin({ ...pin, images: newList });
    } catch (error) {
      if (error.response.data.message === 'Image not found')
        enqueueSnackbar('Imagem não existe');
      else {
        enqueueSnackbar(error.response.data.message);
        console.error(error.response.data.message);
      }
    }
  };

  const onUpload = (files) => {
    if (files) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i]);
      }
      uploadNewImages(
        setLoading,
        pin.id,
        formData,
        enqueueSnackbar,
        handleNewImages
      );
    }
  };

  const handleNewImages = (data) => {
    const newImageList = data.map((imageData) => {
      const { id, imageName } = imageData;
      return {
        id,
        title: imageName,
      };
    });
    setPin({ ...pin, images: newImageList });
  };

  const onSubmit = () => {
    const { description, id, title } = pin;
    let { finishDate, startDate, url } = pin;
    console.log(url);

    if (!isEvent) {
      finishDate = null;
      startDate = null;
      url = null;
    }
    const categoriesIds = pin.categories.map((category) => {
      return category.id;
    });
    const lat = position.lat.toString();
    const long = position.lng.toString();

    updatePin(
      setLoading,
      {
        description,
        finishDate,
        id,
        startDate,
        title,
        categoriesIds,
        lat,
        long,
        lang: 'pt',
        url,
      },
      enqueueSnackbar
    );
  };

  function handleStartDate(date: Date) {
    setPin({ ...pin, startDate: date.toISOString() });
  }

  function handleFinishtDate(date: Date) {
    setPin({ ...pin, finishDate: date.toISOString() });
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Container component="main">
              {pin !== null && !loading ? (
                <div>
                  <Title>Editar Ponto</Title>
                  <input
                    accept="image/*"
                    className={classes.input}
                    multiple
                    id="contained-button-file"
                    type="file"
                    onChange={(event) => {
                      onUpload(event.target.files);
                    }}
                  />
                  <label
                    htmlFor="contained-button-file"
                    placeholder="Carregar imagem"
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.addImage}
                      size="large"
                      aria-label="upload picture"
                      component="span"
                      startIcon={
                        <AddIcon fontSize="large" style={{ color: '#fff' }} />
                      }
                    />
                  </label>

                  <GridList className={classes.gridList} cols={4}>
                    {pin.images.map((image) => (
                      <GridListTile key={image.id}>
                        <img src={`${baseURL}/pins/img/${image.id}`} alt=" " />
                        <GridListTileBar
                          titlePosition="top"
                          className={classes.titleBar}
                          actionIcon={
                            <IconButton
                              aria-label={`Apagar`}
                              onClick={() => handleRemoveImage(image.id)}
                            >
                              <DeleteIcon className={classes.deleteIcon} />
                            </IconButton>
                          }
                        />
                      </GridListTile>
                    ))}
                  </GridList>
                  <Container className={classes.content_box}>
                    <Typography variant="h6" component="h2">
                      Título:
                    </Typography>
                    <TextField
                      id="outlined-textarea"
                      variant="outlined"
                      name="title"
                      value={pin.title}
                      onChange={onChange}
                      fullWidth
                    />
                  </Container>
                  <Container className={classes.content_box}>
                    <Typography variant="h6" component="h2">
                      Descrição:
                    </Typography>
                    <TextField
                      id="outlined-textarea"
                      multiline
                      variant="outlined"
                      rows={4}
                      fullWidth
                      value={pin.description}
                      onChange={onChange}
                      name="description"
                    />
                  </Container>
                  <Container className={classes.content_box}>
                    <Typography variant="h6" component="h2">
                      Categorias:
                    </Typography>
                    <FormControl fullWidth className={classes.formControl}>
                      <Select
                        labelId="multiple-categories-label"
                        id="mutiple-categories-chip"
                        multiple
                        value={pin.categories.map((category) => category.name)}
                        onChange={handleSeclectCategoryClick}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {(selected as string[]).map((value, index) => {
                              const color = pin.categories[index].color;
                              return (
                                <Chip
                                  avatar={
                                    <Avatar
                                      style={{
                                        backgroundColor: color,
                                      }}
                                    >
                                      {' '}
                                    </Avatar>
                                  }
                                  key={value}
                                  label={value}
                                  className={classes.chip}
                                  size="small"
                                />
                              );
                            })}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.id} value={category.name}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Container>
                  <Container className={classes.content_box}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isEvent}
                          color="primary"
                          onChange={handleTypeClick}
                          name="checkedEvent"
                        />
                      }
                      label="Evento"
                    />
                    <Collapse in={isEvent} timeout="auto" unmountOnExit>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          variant="inline"
                          label="Data de inicio"
                          value={pin.startDate}
                          name="startDate"
                          onChange={handleStartDate}
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          variant="inline"
                          label="Data de fim"
                          value={pin.finishDate}
                          name="finishDate"
                          onChange={handleFinishtDate}
                        />
                      </MuiPickersUtilsProvider>
                      <Typography
                        style={{ paddingTop: 20 }}
                        variant="subtitle1"
                        component="h4"
                      >
                        Url:
                      </Typography>
                      <TextField
                        id="url-textarea"
                        // variant="standard"
                        fullWidth
                        value={pin.url}
                        onChange={onChange}
                        name="url"
                      />
                    </Collapse>
                    <MapContainer
                      center={position}
                      zoom={13}
                      className={classes.leaflet_container}
                    >
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                      <LocationMarker
                        position={position}
                        setPosition={setPosition}
                      />
                    </MapContainer>
                  </Container>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    aria-label="upload picture"
                    color="primary"
                    component="span"
                    onClick={onSubmit}
                    disabled={
                      !pin.title ||
                      !pin.description ||
                      (isEvent && (!pin.startDate || !pin.finishDate)) ||
                      !pin.categories ||
                      loading
                    }
                    startIcon={
                      <SaveIcon fontSize="large" style={{ color: '#fff' }} />
                    }
                  />
                </div>
              ) : (
                <CircularProgress />
              )}
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(EditPin);
