import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useSnackbar } from 'notistack';

import { useStyles } from './loginStyles';
import AuthContext from '../../context/auth/authContext';
import { canIManageUser } from '../../utils/parseJWT';

const Login = (props) => {
  // console.log("I'm Login");
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const authContext = useContext(AuthContext);
  const { login, error, isAuthenticated, clearErrors } = authContext;

  useEffect(() => {
    authContext.loadUser();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // authContext.loadUser();
    if (isAuthenticated) {
      if (localStorage.token && canIManageUser(localStorage.token))
        props.history.push('/users');
      else props.history.push('/');
    }

    if (error) {
      enqueueSnackbar('E-mail e/ou password incorretos', {
        variant: 'error',
        persist: true,
      });
      // console.log('error: ' + error);
      clearErrors();
    }
    //eslint-disable-next-line
  }, [error, isAuthenticated]);

  const [user, setUser] = useState({ username: '', password: '' });

  const onChange = (e: { target: { name: string; value: string } }) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    closeSnackbar();
  };

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    login(user);
  };

  return (
    <Container component="main" maxWidth="xs">
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
        <Avatar className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Entrar
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={onChange}
            id="username"
            label="Email"
            name="username"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={onChange}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default React.memo(Login);
