import axios from 'axios';

export const submit = async (
  setLoading,
  { id, title, description, lang },
  enqueueSnackbar,
  onEdit
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.token,
    },
  };
  try {
    setLoading(true);
    // console.log(formData);

    await axios.post(`/pins/lang/${id}`, { title, description, lang }, config);
    onEdit(id, title, description);
    //success message
    enqueueSnackbar(`Tradução alterada com sucesso!`, {
      variant: 'success',
      autoHideDuration: 2000,
    });
  } catch (error) {
    //error message
    const msg = error.response.data.message;

    enqueueSnackbar(msg, {
      variant: 'warning',
    });
  } finally {
    setLoading(false);
  }
};
