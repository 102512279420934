export default function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
export function getMyUsername(token: string): string {
  const payload = parseJwt(token);
  return payload.username;
}

function getMyActions(token: string): string[] {
  const payload = parseJwt(token);
  return payload.actions;
}

export function canIManageUser(token: string): boolean {
  const actions = getMyActions(token);
  return actions.includes('canManageUser');
}

export function canIEditCategory(token: string): boolean {
  const actions = getMyActions(token);
  return actions.includes('canEditCategories');
}

export function canIRemoveCategory(token: string): boolean {
  const actions = getMyActions(token);
  return actions.includes('canRemoveCategories');
}

export function canIAddCategory(token: string): boolean {
  const actions = getMyActions(token);
  return actions.includes('canAddCategories');
}

export function canIEditPin(token: string): boolean {
  const actions = getMyActions(token);
  return actions.includes('canEditPins');
}

export function canIRemovePin(token: string): boolean {
  const actions = getMyActions(token);
  return actions.includes('canRemovePins');
}

export function canIAddPin(token: string): boolean {
  const actions = getMyActions(token);
  return actions.includes('canAddPins');
}

export function canIAddLanguages(token: string): boolean {
  const actions = getMyActions(token);
  return actions.includes('canAddLanguages');
}

export function canIRemoveLanguages(token: string): boolean {
  const actions = getMyActions(token);
  return actions.includes('canRemoveLanguages');
}
