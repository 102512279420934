import { TextField } from '@material-ui/core';
import React from 'react';

const EditPassword = ({ user, setUser }) => {
  const onChange = (e: { target: { name: string; value: string } }) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  return (
    <div>
      <TextField
        margin="normal"
        required
        fullWidth
        onChange={onChange}
        name="password"
        label="Password Atual"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        onChange={onChange}
        name="newPassword"
        label="Nova Password"
        type="password"
        id="newPassword"
        autoComplete="current-password"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        onChange={onChange}
        name="confirmPassword"
        label="Confirmar Nova Password"
        type="password"
        id="confirmPassword"
        autoComplete="current-password"
      />
    </div>
  );
};

export default React.memo(EditPassword);
