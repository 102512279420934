import {
  Avatar,
  Chip,
  createStyles,
  IconButton,
  makeStyles,
  TableRow,
  Theme,
} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import { useHistory } from 'react-router-dom';
// import EditButton from './edit/EditButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import QuestionMarkIcon from '@material-ui/icons/Help';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { baseURL } from '../../../utils/globals';
import { canIEditPin, canIRemovePin } from '../../../utils/parseJWT';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    space: {
      margin: theme.spacing(0, 5),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    item: {
      width: '100%',
    },
  })
);

const PinListItem = ({ pin, onRemove }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const {
    id,
    title,
    description,
    images,
    categories,
    startDate,
    finishDate,
  } = pin;

  const handleDeleteClick = async (id: string) => {
    try {
      await axios.delete(`/pins/${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      enqueueSnackbar(`Ponto removido com sucesso`);
      onRemove(id);
    } catch (error) {
      if (error.response.data.message === 'Pin not found')
        enqueueSnackbar('Ponto não existe');
      else {
        enqueueSnackbar(error.response.data.message);
        console.error(error.response.data.message);
      }
    }
  };

  const handleItemClick = async () => {
    if (localStorage.token && canIEditPin(localStorage.token))
      history.push('/pins/edit', { id });
  };

  return (
    <TableRow key={id} hover>
      <TableCell onClick={() => handleItemClick()}>
        {images[0] ? (
          <Avatar
            variant="square"
            src={`${baseURL}/pins/img/${images[0].id}?${Date.now()}`}
            alt={images[0].title}
            className={classes.large}
          />
        ) : (
          <Avatar variant="square" className={classes.large}>
            <QuestionMarkIcon />
          </Avatar>
        )}
      </TableCell>
      <TableCell onClick={() => handleItemClick()}>{title}</TableCell>
      <TableCell onClick={() => handleItemClick()}>
        {categories.map((category) => (
          <Chip
            key={category.id}
            label={category.name}
            avatar={
              <Avatar
                style={{
                  backgroundColor: category.color,
                }}
              >
                {' '}
              </Avatar>
            }
            className={classes.chip}
            size="small"
          />
        ))}
      </TableCell>
      <TableCell onClick={() => handleItemClick()}>{description}</TableCell>
      <TableCell onClick={() => handleItemClick()}>
        {startDate && finishDate ? 'Evento' : 'Turistico'}
      </TableCell>
      <TableCell>
        {localStorage.token && canIEditPin(localStorage.token) ? (
          <IconButton aria-label="edit" onClick={() => handleItemClick()}>
            <EditIcon />
          </IconButton>
        ) : (
          <div />
        )}
        {localStorage.token && canIRemovePin(localStorage.token) ? (
          <IconButton aria-label="delete" onClick={() => handleDeleteClick(id)}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <div />
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(PinListItem);
