import {
  Avatar,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import EditButton from './edit/EditButton';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { canIEditCategory, canIRemoveCategory } from '../../../utils/parseJWT';
import { baseURL } from '../../../utils/globals';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    x_large: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
);

const ListItem = ({ category, onRemove, onEdit }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { name, color, id } = category;

  const handleDeleteClick = async (id: string) => {
    // console.log(id);
    try {
      await axios.delete(`/categories/${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      enqueueSnackbar(`Categoria removida com sucesso`);
      onRemove(id);
    } catch (error) {
      if (error.response.data.message === 'Category not found')
        enqueueSnackbar('Categoria não existe');
      else {
        enqueueSnackbar(error.response.data.message);
        console.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      <TableCell>
        <Avatar
          // key={Date.now()}
          variant="square"
          src={`${baseURL}/categories/img/${id}?${Date.now()}`}
          alt="?"
          className={classes.large}
        />
      </TableCell>

      <TableCell>
        <b style={{ color: '#888' }}>{name.toUpperCase()}</b>
      </TableCell>

      <TableCell
        onClick={() => <EditButton category={category} onEdit={onEdit} />}
      >
        <Avatar style={{ backgroundColor: color }}> </Avatar>
      </TableCell>
      <TableCell>
        {localStorage.token && canIEditCategory(localStorage.token) ? (
          <EditButton category={category} onEdit={onEdit} />
        ) : (
          <div />
        )}
        {localStorage.token && canIRemoveCategory(localStorage.token) ? (
          <IconButton aria-label="delete" onClick={() => handleDeleteClick(id)}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <div />
        )}
      </TableCell>
    </>
  );
};

export default React.memo(ListItem);
