import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

import Button from '@material-ui/core/Button';
import { UserDto } from '@cmmv-turismo/data';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Title from '../../../layout/Title';
import { Box, CircularProgress, Collapse, IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useStyles, useStylesAlt } from './addCategoryStyles';
import axios from 'axios';
import SelectRole from '../../../layout/users/SelectRole';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { submit } from './submit';

const AddCategory = ({ onAdd }) => {
  const classes = useStyles();
  const [openColor, setOpenColor] = React.useState(false);
  const handleOpenColorClick = () => {
    setOpenColor(!openColor);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState({
    name: '',
    file: null,
    filename: null,
    color: '#4745F3',
  });

  const onChange = (e: { target: { name: string; value: string } }) =>
    setCategory({ ...category, [e.target.name]: e.target.value });

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (category.file) {
      const formData = new FormData();
      formData.append('image', category.file, category.filename);
      formData.append('name', category.name);
      formData.append('lang', 'pt');
      formData.append('color', category.color);

      submit(setLoading, formData, enqueueSnackbar, onAdd);
    } else {
      enqueueSnackbar('Insere uma imagem', {
        variant: 'info',
      });
    }

    // submit(setLoading, user, enqueueSnackbar, onAdd);
  };

  const handleChangeComplete = (color, event) => {
    // console.log(color.hex);
    setCategory({ ...category, color: color.hex });
  };

  const onUpload = (files) => {
    setCategory({ ...category, file: files[0], filename: files[0].name });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Title>Adicionar Categoria</Title>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            onChange={onChange}
            id="name"
            label="Nome"
            name="name"
            autoComplete="categoryName"
            autoFocus
          />
          <Box className={classes.box}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={(event) => {
                onUpload(event.target.files);
              }}
            />
            <label
              htmlFor="contained-button-file"
              placeholder="Carregar imagem"
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <PhotoLibraryIcon fontSize="large" />
              </IconButton>
            </label>
            <Button
              onClick={handleOpenColorClick}
              variant="contained"
              color="primary"
              className={classes.buttons}
            >
              Cor
            </Button>
            <Collapse in={openColor} timeout="auto" unmountOnExit>
              <ChromePicker
                color={category.color}
                onChange={handleChangeComplete}
              />
            </Collapse>
          </Box>

          <Button
            type="submit"
            variant="contained"
            disabled={!category.name || loading}
            color="primary"
            className={classes.submit}
          >
            Adicionar
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </form>
      </div>
    </Container>
  );
};

export default React.memo(AddCategory);
