import React, { useReducer } from 'react';
import {
  AUTH_ERROR,
  CLEAR_ERRORS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED,
} from '../types';
import authContext, { User } from './authContext';
import authReducer from './authReducer';
import useSWR from 'swr';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    user: null,
    loading: true,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // const fetcher = (...args: [input: RequestInfo, init?: RequestInit | undefined]) => fetch(...args).then(response => response.json());
  // const {data, error} = useSWR('/auth/signin', fetcher)

  const loadUser = async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    try {
      const res = await axios.get('/auth', {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });

      dispatch({ type: USER_LOADED, payload: res.data });
    } catch (error) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  const login = async (formData: User) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post('/auth/signin', formData, config);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      loadUser();
    } catch (error) {
      dispatch({
        type: LOGIN_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  const logout = () => dispatch({ type: LOGOUT });

  return (
    <authContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        loading: state.loading,
        error: state.error,
        login,
        logout,
        clearErrors,
        loadUser,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthState;
