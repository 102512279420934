import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Title from '../../../layout/Title';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useStyles } from './addPinStyles';
import axios from 'axios';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { submit } from './submit';
import LocationMarker from './../../../../utils/LocationMarker';
import { LatLng } from 'leaflet';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddPin = ({ onAdd }) => {
  const classes = useStyles();
  const [isEvent, setIsEvent] = React.useState(false);
  const handleTypeClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEvent(event.target.checked);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState({
    title: '',
    description: '',
    url: null,
    startDate: null,
    finishDate: null,
    location: { lat: 40.1725, lng: -8.68465 },
    files: null,
  });
  const [pinCategories, setPinCategories] = useState<
    {
      id: string;
      name: string;
      color: string;
    }[]
  >([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategories = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/categories/', {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const newList = res.data.map((categoryData, index) => {
        const { color, id, categoryLanguages } = categoryData;
        const name = categoryLanguages[0].name;
        return { id, name, color };
      });
      setCategories(newList);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [position, setPosition] = useState<LatLng>(null);

  const onChange = (e: { target: { name: string; value: string } }) => {
    setPin({ ...pin, [e.target.name]: e.target.value });
  };
  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (pin.files) {
      const formData = new FormData();
      if (isEvent) {
        if (!pin.startDate) {
          enqueueSnackbar('Insere uma data de inicio', {
            variant: 'error',
          });
          return;
        }
        if (!pin.finishDate) {
          enqueueSnackbar('Insere uma data de fim', {
            variant: 'error',
          });
          return;
        }
        if (pin.finishDate && pin.startDate) {
          formData.append('startDate', pin.startDate);
          formData.append('finishDate', pin.finishDate);
        }
      }
      for (let i = 0; i < pin.files.length; i++) {
        formData.append('images', pin.files[i]);
      }

      formData.append('title', pin.title);
      formData.append('description', pin.description);
      formData.append('lat', position.lat.toString());
      formData.append('long', position.lng.toString());
      formData.append('url', pin.url);
      formData.append('lang', 'pt');

      pinCategories.forEach((category) => {
        formData.append('categoriesIds', category.id);
      });
      submit(setLoading, formData, enqueueSnackbar, onAdd);
    } else {
      enqueueSnackbar('Insere uma imagem', {
        variant: 'error',
      });
    }
  };
  const handleSeclectCategoryClick = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const names = event.target.value as string[];
    const selected = categories.filter((category) =>
      names.includes(category.name)
    );
    setPinCategories(selected);
  };

  const onUpload = (files) => {
    setPin({ ...pin, files: files });
  };

  function handleStartDate(date: Date) {
    setPin({ ...pin, startDate: date.toISOString() });
  }

  function handleFinishtDate(date: Date) {
    setPin({ ...pin, finishDate: date.toISOString() });
  }

  return (
    <Container component="main" maxWidth="md">
      <form className={classes.form} onSubmit={onSubmit}>
        <Title>Adicionar Ponto</Title>
        <Box className={classes.main_box}>
          <TextField
            margin="normal"
            required
            onChange={onChange}
            id="title"
            label="Título"
            name="title"
            autoComplete="pinTitle"
            fullWidth
            autoFocus
          />
          <TextField
            margin="normal"
            required
            onChange={onChange}
            id="description"
            label="Descrição"
            name="description"
            autoComplete="pinDescription"
            fullWidth
          />
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="multiple-categories-label">Categorias</InputLabel>
            <Select
              labelId="multiple-categories-label"
              id="mutiple-categories-chip"
              multiple
              value={pinCategories.map((category) => category.name)}
              onChange={handleSeclectCategoryClick}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as string[]).map((value, index) => {
                    const color = pinCategories[index].color;
                    return (
                      <Chip
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor: color,
                            }}
                          >
                            {' '}
                          </Avatar>
                        }
                        key={value}
                        label={value}
                        className={classes.chip}
                        size="small"
                      />
                    );
                  })}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <input
            accept="image/*"
            className={classes.input}
            multiple
            id="contained-button-file"
            type="file"
            onChange={(event) => {
              onUpload(event.target.files);
            }}
          />
          <label htmlFor="contained-button-file" placeholder="Carregar imagem">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<PhotoLibraryIcon />}
              aria-label="upload picture"
              component="span"
            >
              Selecionar imagens
            </Button>
          </label>
          <FormControlLabel
            control={
              <Checkbox
                checked={isEvent}
                color="primary"
                onChange={handleTypeClick}
                name="checkedEvent"
              />
            }
            label="Evento"
          />
          <Collapse in={isEvent} timeout="auto" unmountOnExit>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                variant="inline"
                label="Data de inicio"
                value={pin.startDate}
                name="startDate"
                onChange={handleStartDate}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                variant="inline"
                label="Data de fim"
                value={pin.finishDate}
                name="finishDate"
                onChange={handleFinishtDate}
              />
            </MuiPickersUtilsProvider>

            <TextField
              margin="normal"
              onChange={onChange}
              id="url"
              label="Url"
              name="url"
              autoComplete="pinUrl"
              fullWidth
            />
          </Collapse>
          <MapContainer
            center={pin.location}
            zoom={13}
            className={classes.leaflet_container}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            <LocationMarker position={position} setPosition={setPosition} />
          </MapContainer>
        </Box>

        <Button
          type="submit"
          variant="contained"
          disabled={
            !pin.title ||
            !pin.description ||
            loading ||
            pinCategories.length === 0 ||
            !position ||
            (isEvent && (!pin.startDate || !pin.finishDate))
          }
          color="primary"
          className={classes.submit}
        >
          Adicionar
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </form>
    </Container>
  );
};

export default React.memo(AddPin);
