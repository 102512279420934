import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    flex: '1 1 50%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200,
  },
  box: {
    padding: theme.spacing(2),
    width: '45%',
    justifyContent: 'center',
  },
  main_box: {
    width: '100%',
    justifyContent: 'center',
  },
  button: {
    width: '100%',
    justifyContent: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    justifyContent: 'center',
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  chip: {
    margin: 2,
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  leaflet_container: {
    width: '100%',
    height: '300px',
    marginTop: theme.spacing(4),
  },

  main_container: {
    flex: '1 1 50%',
    justifyContent: 'center',
  },
}));
