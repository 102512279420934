import { createContext } from 'react';

export interface User {
  username: string;
  password: string;
}

interface IAuthContextProps {
  token: string;
  isAuthenticated: boolean;
  user: User;
  loading: boolean;
  error: string;
  login: (formData: User) => void;
  clearErrors: () => void;
  loadUser: () => void;
  logout: () => void;
}

const authContext = createContext<IAuthContextProps>({} as IAuthContextProps);

export default authContext;
