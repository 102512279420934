import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import { canIManageUser } from '../../utils/parseJWT';

const AdminRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading } = authContext;
  if (!localStorage.token) {
    return <Route {...rest} render={(props) => <Redirect to="/login" />} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        (!isAuthenticated || !canIManageUser(localStorage.token)) &&
        !loading ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default AdminRoute;
