import React, { useContext, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import { useStyles } from './listUsersStyles';
import Title from '../../layout/Title';
import AddButton from './add/AddButton';
import ListItem from './ListItem';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import AuthContext from '../../../context/auth/authContext';
import parseJwt from '../../../utils/parseJWT';
import EnhancedTableToolbar from '../../layout/EnhancedTableToolbar';
import EnhancedTableHead from '../../layout/EnhancedTableHead';
import useSWR from 'swr';

const ListUsers = () => {
  // console.log("I'm ListUsers");

  const authContext = useContext(AuthContext);

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([
    {
      id: 0,
      username: '',
      password: '',
      role: '',
    },
  ]);

  // // const fetcher = (...args: [input: RequestInfo, init?: RequestInit | undefined]) => fetch(...args).then(response => response.json());
  // // const {data, error} = useSWR('/auth/signin', fetcher)
  // const getUsers = async () => {
  //   const fetcher = (...args: [input: RequestInfo, init?: RequestInit | undefined]) => fetch(...args).then(response => response.json());
  //   const {data, error} = useSWR('/users/list', fetcher)

  //   try {
  //     setLoading(true);
  //     const res = await axios.get('/users/list', {
  //       headers: {
  //         Authorization: 'Bearer ' + localStorage.token,
  //       },
  //     });
  //     const newList = res.data.map((userData, index) => {
  //       const { username, id, roles } = userData;
  //       const roleName =
  //         roles[0].name === 'Administrator'
  //           ? 'Administrador'
  //           : 'Gestor de Conteúdo';
  //       return { id, username, role: roleName };
  //     });
  //     setUsers(newList);
  //     // console.log(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/users/list', {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const newList = res.data.map((userData, index) => {
        const { username, id, roles } = userData;
        const roleName =
          roles[0].name === 'Administrator'
            ? 'Administrador'
            : 'Gestor de Conteúdo';
        return { id, username, role: roleName };
      });
      setUsers(newList);
      // console.log(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  function handleRemove(id: number) {
    const newList = users.filter((user) => user.id !== id);

    setUsers(newList);
  }
  function handleAdd(user) {
    setUsers([...users, user]);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Container component="main" maxWidth="md">
              <EnhancedTableToolbar
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                length={users.length}
                page={page}
                rowsPerPage={rowsPerPage}
                title="Utilizadores"
              />
              {users !== null && !loading ? (
                <Table size="small">
                  <EnhancedTableHead titles={['Email', 'Função']} />
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.id}>
                        <ListItem user={user} onRemove={handleRemove} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <CircularProgress />
              )}
              <AddButton onAdd={handleAdd} />
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(ListUsers);
