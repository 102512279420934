import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Title from '../../../layout/Title';
import {
  Box,
  CircularProgress,
  Collapse,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useStyles } from './editUserStyles';
import axios from 'axios';
import SelectRole from '../../../layout/users/SelectRole';
import { updateUserPassword, updateUserRole } from './submit';
import EditPassword from './EditPassword';
import EditRole from './EditRole';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const EditUser = ({ username }) => {
  // console.log("I'm EditUser");
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openRole, setOpenRole] = React.useState(false);
  const [openPass, setOpenPass] = React.useState(false);

  const handleRoleClick = () => {
    setOpenPass(false);
    setOpenRole(!openRole);
  };
  const handlePassClick = () => {
    setOpenRole(false);
    setOpenPass(!openPass);
  };
  const [user, setUser] = useState({
    username: username,
    password: '',
    newPassword: '',
    confirmPassword: '',
    role: '',
  });

  // const { password, newPassword } = user;
  const [loading, setLoading] = useState(false);

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (openPass) {
      if (user.newPassword === user.confirmPassword) {
        updateUserPassword(setLoading, user, enqueueSnackbar);
      } else
        enqueueSnackbar('Password e confirmação de Password não correspondem', {
          variant: 'warning',
        });
    }
    if (openRole) {
      updateUserRole(setLoading, user, enqueueSnackbar);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
        <Title>Editar Utilizador</Title>
        <form className={classes.form} onSubmit={onSubmit}>
          <ListItem button onClick={handleRoleClick}>
            <ListItemText primary="Função" />
            {openRole ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openRole} timeout="auto" unmountOnExit>
            <EditRole user={user} setUser={setUser} />
          </Collapse>
          <ListItem button onClick={handlePassClick}>
            <ListItemText primary="Password" />
            {openPass ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openPass} timeout="auto" unmountOnExit>
            <EditPassword user={user} setUser={setUser} />
          </Collapse>

          <Box component="span" className={classes.buttonBox}>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                disabled={(!openPass && !openRole) || loading}
                color="primary"
                className={classes.submit}
              >
                Alterar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </form>
      </div>
    </Container>
  );
};

export default React.memo(EditUser);
