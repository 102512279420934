import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../context/auth/authContext';
import { canIAddCategory } from '../../../utils/parseJWT';
import EnhancedTableHead from '../../layout/EnhancedTableHead';
import EnhancedTableToolbar from '../../layout/EnhancedTableToolbar';
import AddButton from './add/AddButton';
import { useStyles } from './categoryListStyles';
import ListItem from './ListItem';

const CategoryList = () => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  const getCategories = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/categories/lang/pt', {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const newList = res.data.map((categoryData, index) => {
        const { color, id, categoryLanguages } = categoryData;
        const name = categoryLanguages[0].name;
        return { id, name, color };
      });
      // console.log(newList);
      // console.log(res.data);

      setCategories(newList);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleAdd(data) {
    const { categoryLanguages, color, id } = data;
    const name = categoryLanguages[0].name;
    setCategories([...categories, { id, name, color }]);
  }

  function handleRemove(id: string) {
    const newList = categories.filter((category) => category.id !== id);

    setCategories(newList);
  }
  function handleEdit(id: string, name: string, color: string) {
    // console.log(id, name, color);
    // const name = data.categoryLanguages[0].name;
    setCategories((categories) => {
      const list = categories.map((category) => {
        if (category.id === id) {
          category.name = name;
          category.color = color;
        }
        return category;
      });
      return list;
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Container component="main" maxWidth="md">
              <EnhancedTableToolbar
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                length={categories.length}
                page={page}
                rowsPerPage={rowsPerPage}
                title="Categorias"
              />
              {categories !== null && !loading ? (
                <Table size="small">
                  <EnhancedTableHead titles={['Imagem', 'Nome', 'Cor']} />
                  <TableBody>
                    {categories
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((category) => (
                        <TableRow key={category.id}>
                          <ListItem
                            category={category}
                            onRemove={handleRemove}
                            onEdit={handleEdit}
                          />
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <CircularProgress />
              )}
              {localStorage.token && canIAddCategory(localStorage.token) ? (
                <AddButton onAdd={handleAdd} />
              ) : (
                <div />
              )}
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(CategoryList);
