import React, { useRef, useState } from 'react';
import { ChromePicker } from 'react-color';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Title from '../../../layout/Title';
import { Box, CircularProgress, Collapse, IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useStyles } from './editCategoryStyles';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { updateCategory, updateCategoryAndImage } from './submit';

const EditCategory = ({ category: { id, name, color }, onEdit }) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openColor, setOpenColor] = React.useState(false);
  const handleOpenColorClick = () => {
    setOpenColor(!openColor);
  };

  const [category, setCategory] = useState({
    name,
    id,
    color,
    file: null,
    filename: null,
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (category.file) {
      updateCategoryAndImage(setLoading, category, enqueueSnackbar, onEdit);
    } else {
      updateCategory(setLoading, category, 'pt', enqueueSnackbar, onEdit);
    }
  };
  const onChange = (e: { target: { name: string; value: string } }) =>
    setCategory({ ...category, [e.target.name]: e.target.value });

  const handleChangeComplete = (color, event) => {
    setCategory({ ...category, color: color.hex });
  };

  const onUpload = (files) => {
    setCategory({ ...category, file: files[0], filename: files[0].name });
  };

  return (
    <Container component="main" maxWidth="xs">
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
        <Title>Editar Categoria</Title>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            onChange={onChange}
            id="name"
            label="Nome"
            name="name"
            autoComplete="categoryName"
            autoFocus
            value={category.name}
          />

          <Box className={classes.box}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={(event) => {
                onUpload(event.target.files);
              }}
            />
            <label
              htmlFor="contained-button-file"
              placeholder="Carregar imagem"
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <PhotoLibraryIcon fontSize="large" />
              </IconButton>
            </label>
            <Button
              onClick={handleOpenColorClick}
              variant="contained"
              color="primary"
              className={classes.buttons}
            >
              Cor
            </Button>
            <Collapse in={openColor} timeout="auto" unmountOnExit>
              <ChromePicker
                color={category.color}
                onChange={handleChangeComplete}
              />
            </Collapse>
          </Box>

          <Box component="span" className={classes.buttonBox}>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                color="primary"
                className={classes.submit}
              >
                Alterar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </form>
      </div>
    </Container>
  );
};

export default React.memo(EditCategory);
