import {
  createStyles,
  IconButton,
  makeStyles,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import React, { Fragment } from 'react';
// import EditButton from './edit/EditButton';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import EditIcon from '@material-ui/icons/Edit';
import { canIEditCategory, canIEditPin } from '../../../utils/parseJWT';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    space: {
      margin: theme.spacing(0, 5),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    item: {
      width: '100%',
    },
    missing: {
      color: 'red',
    },
  })
);

const PinListItem = ({
  lang,
  missingPins,
  missingCategories,
  index,
  onRemove,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  //   const { id, name } = lang;

  const handleDeleteClick = async (id: string) => {
    try {
      await axios.delete(`/langs/${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      enqueueSnackbar(`Lingua removida com sucesso`);
      onRemove(id);
    } catch (error) {
      if (error.response.data.message === 'Language not found')
        enqueueSnackbar('Lingua não existe');
      else {
        enqueueSnackbar(error.response.data.message);
        console.error(error.response.data.message);
      }
    }
  };

  const handlePinsClick = async () => {
    const languageId = lang.id;
    if (localStorage.token && canIEditPin(localStorage.token))
      history.push('/languages/pins', { languageId });
  };
  const handleCategoriesClick = async () => {
    const languageId = lang.id;
    if (localStorage.token && canIEditCategory(localStorage.token))
      history.push('/languages/categories', { languageId });
  };

  return (
    <TableRow hover>
      <TableCell>{lang.id}</TableCell>
      <TableCell>{lang.name}</TableCell>
      <TableCell onClick={() => handlePinsClick()}>
        {missingPins !== 0 ? (
          <Typography
            variant="subtitle2"
            className={classes.missing}
            component="h2"
          >
            {missingPins}
          </Typography>
        ) : (
          <Typography variant="subtitle2" component="h2">
            {missingPins}
          </Typography>
        )}
      </TableCell>
      <TableCell onClick={() => handlePinsClick()}>
        <IconButton aria-label="edit-pin">
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell onClick={() => handleCategoriesClick()}>
        {missingCategories !== 0 ? (
          <Typography
            variant="subtitle2"
            className={classes.missing}
            component="h2"
          >
            {missingCategories}
          </Typography>
        ) : (
          <Typography variant="subtitle2" component="h2">
            {missingCategories}
          </Typography>
        )}
      </TableCell>
      <TableCell onClick={() => handleCategoriesClick()}>
        <IconButton aria-label="edit-category">
          <EditIcon />
        </IconButton>
      </TableCell>
      {index === 0 ? (
        <TableCell />
      ) : (
        <TableCell>
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteClick(lang.id)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default React.memo(PinListItem);
