import axios from 'axios';

export const updateUserPassword = async (
  setLoading,
  { username, password, newPassword },
  enqueueSnackbar
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.token,
    },
  };
  try {
    setLoading(true);
    await axios.patch(
      '/users/update',
      { username, password, newPassword },
      config
    );
    //success message
    enqueueSnackbar(`Password de ${username} alterada com sucesso!`, {
      variant: 'success',
      autoHideDuration: 2000,
    });
  } catch (error) {
    //error message
    let msg;
    if (Array.isArray(error.response.data.message)) {
      msg = error.response.data.message[0];
    } else {
      msg = error.response.data.message;
    }

    if (msg === 'Incorrect password') msg = 'Password atual incorrecta';
    else if (msg === "New password can't be your old password")
      msg = 'A nova password é igual à anterior';
    enqueueSnackbar(msg, {
      variant: 'warning',
    });
  } finally {
    setLoading(false);
  }
};
export const updateUserRole = async (
  setLoading,
  { username, role },
  enqueueSnackbar
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.token,
    },
  };
  try {
    setLoading(true);
    await axios.patch('/users/update-role', { username, role }, config);
    //success message

    enqueueSnackbar(`Função de ${username} alterada com sucesso!`, {
      variant: 'success',
      autoHideDuration: 2000,
    });
  } catch (error) {
    //error message
    const msg = error.response.data.message;
    enqueueSnackbar(msg, {
      variant: 'warning',
    });
  } finally {
    setLoading(false);
  }
};
