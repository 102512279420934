import React, { useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Title from '../../../../layout/Title';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useStyles } from './editPinLanguageStyles';
import { submit } from './submit';

const EditPinLanguage = ({
  pin,
  ptTitle,
  ptDescription,
  languageId,
  onEdit,
}) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [editedPin, setEditedPin] = useState(pin);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    submit(
      setLoading,
      {
        title: editedPin.title,
        id: editedPin.id,
        description: editedPin.description,
        lang: languageId,
      },
      enqueueSnackbar,
      onEdit
    );
  };
  const onChange = (e: { target: { name: string; value: string } }) =>
    setEditedPin({ ...editedPin, [e.target.name]: e.target.value });

  return (
    <Container component="main" maxWidth="md">
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
        <Title>Editar Tradução</Title>
        <form className={classes.form} onSubmit={onSubmit}>
          <Typography variant="body1" style={{ color: '#888' }} component="h2">
            Titulo
          </Typography>
          <Typography variant="body1" component="h2">
            {ptTitle}
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            onChange={onChange}
            id="title"
            label="Tradução"
            name="title"
            value={editedPin.title}
            autoFocus
          />
          <Typography
            className={classes.input}
            style={{ color: '#888' }}
            variant="body1"
            component="h2"
          >
            Descrição
          </Typography>
          <Typography variant="body1" component="h2">
            {ptDescription}
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            onChange={onChange}
            id="description"
            label="Tradução"
            name="description"
            value={editedPin.description}
          />

          <Box component="span">
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                color="primary"
                className={classes.submit}
              >
                Alterar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Box>
        </form>
      </div>
    </Container>
  );
};

export default React.memo(EditPinLanguage);
