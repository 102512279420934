import axios from 'axios';

export const submit = async (
  setLoading,
  { username, password, role },
  enqueueSnackbar,
  onAdd
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.token,
    },
  };
  try {
    setLoading(true);
    const res = await axios.post(
      '/auth/signup',
      { username, password, role },
      config
    );
    //success message
    enqueueSnackbar(`Utilizador ${username} adicionado com sucesso!`, {
      variant: 'success',
      autoHideDuration: 2000,
    });
    // console.log(res);
    onAdd(res.data);
  } catch (error) {
    //error message
    let msg;
    if (Array.isArray(error.response.data.message)) {
      msg = error.response.data.message[0];
    } else {
      msg = error.response.data.message;
    }

    if (msg === 'Username already exists')
      msg = `Utilizador ${username} já existe`;
    else if (msg === 'password must be longer than or equal to 8 characters')
      msg = 'Password deve possuir 8 ou mais caracteres';
    else if (msg === 'username must be an email') msg = 'Email inválido';
    enqueueSnackbar(msg, {
      variant: 'warning',
    });
  } finally {
    setLoading(false);
  }
};
