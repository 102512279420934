import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Title from '../../../layout/Title';
import { Box, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useStyles } from './addLanguageStyles';
import { submit } from './submit';

const AddLanguage = ({ onAdd }) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState({
    id: '',
    languageName: '',
  });

  const onChange = (e: { target: { name: string; value: string } }) => {
    // console.log(e.target.value);
    setLang({ ...lang, [e.target.name]: e.target.value });
  };
  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { id, languageName } = lang;
    submit(setLoading, { id, name: languageName }, enqueueSnackbar, onAdd);
  };

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={onSubmit}>
          <Title>Adicionar Lingua</Title>
          <Box className={classes.main_box}>
            <TextField
              margin="normal"
              required
              onChange={onChange}
              id="id"
              label="Identificador"
              name="id"
              autoComplete="id"
              fullWidth
              autoFocus
            />
            <TextField
              margin="normal"
              required
              onChange={onChange}
              id="languageName"
              label="Nome"
              name="languageName"
              autoComplete="languageName"
              fullWidth
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            disabled={!lang.id || !lang.languageName || loading}
            color="primary"
            className={classes.submit}
          >
            Adicionar
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </form>
      </div>
    </Container>
  );
};

export default React.memo(AddLanguage);
