import axios from 'axios';

export const updateCategory = async (
  setLoading,
  { id, name, color },
  lang,
  enqueueSnackbar,
  onEdit
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.token,
    },
  };
  try {
    setLoading(true);
    // console.log(formData);
    /* const res =  */ await axios.post(
      `/categories/${id}`,
      { name, color, lang },
      config
    );
    onEdit(id, name, color, false);
    //success message
    enqueueSnackbar(`Categoria alterada com sucesso!`, {
      variant: 'success',
      autoHideDuration: 2000,
    });
  } catch (error) {
    //error message
    const msg = error.response.data.message;

    enqueueSnackbar(msg, {
      variant: 'warning',
    });
  } finally {
    setLoading(false);
  }
};
export const updateCategoryAndImage = async (
  setLoading,
  { id, name, color, file, filename },
  enqueueSnackbar,
  onEdit
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.token,
    },
  };
  try {
    setLoading(true);
    const formData = new FormData();
    formData.append('name', name);
    formData.append('color', color);
    formData.append('lang', 'pt');
    formData.append('image', file, filename);
    /* const res =  */ await axios.post(
      `/categories/img/${id}`,
      formData,
      config
    );
    onEdit(id, name, color, true);

    //success message
    enqueueSnackbar(`Categoria alterada com sucesso!`, {
      variant: 'success',
      autoHideDuration: 2000,
    });
  } catch (error) {
    //error message
    const msg = error.response.data.message;
    enqueueSnackbar(msg, {
      variant: 'warning',
    });
  } finally {
    setLoading(false);
  }
};
