import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  addImage: {
    alignContent: 'center',
    background:
      'radial-gradient(circle, rgba(130,130,130,1) 0%, rgba(224,224,224,1) 100%)',
  },

  input: {
    display: 'none',
  },
  deleteIcon: {
    color: '#ddd',
  },
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  content_box: {
    margin: theme.spacing(4, 0),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200,
  },
  leaflet_container: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: '300px',
  },
  formControl: {
    // width: '100%',
    margin: theme.spacing(2, 0),
  },

  chips: {
    margin: 2,
  },
}));
