import {
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@material-ui/core';

import React, { useContext, useEffect, useState } from 'react';
import EnhancedTableHead from '../../layout/EnhancedTableHead';
import EnhancedTableToolbar from '../../layout/EnhancedTableToolbar';
import AuthContext from '../../../context/auth/authContext';
import axios from 'axios';
import LanguageListItem from './LanguageListItem';
import AddButton from './add/AddButton';
import { canIAddLanguages } from '../../../utils/parseJWT';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  FloatBtn: {
    marginTop: theme.spacing(4),
  },
}));

export interface pinData {
  id: string;
  languages: string[];
}

interface categoryData {
  id: string;
  languages: string[];
}

const Languages = () => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);

  const [langs, setLangs] = useState<
    { id: string; name: string; pinsCount: number; categoriesCount: number }[]
  >([]);
  const [pins, setPins] = useState<pinData[]>([]);
  const [categories, setCategories] = useState<categoryData[]>([]);

  useEffect(() => {
    getLanguages();

    // eslint-disable-next-line
  }, []);

  const getLanguages = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/langs`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const pinsRes = await axios.get(`/pins/`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const categoriesRes = await axios.get('/categories/', {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const categoriesList = categoriesRes.data.map((categoryData, index) => {
        const { id, categoryLanguages } = categoryData;
        const languages: string[] = categoryLanguages.map(
          (categoryLanguage) => categoryLanguage.languageId
        );
        return { id, languages };
      });
      setCategories(categoriesList);
      const pinsList: pinData[] = pinsRes.data.map((pinData, index) => {
        const { id, pinLanguages } = pinData;
        const languages: string[] = pinLanguages.map(
          (pinLanguage) => pinLanguage.languageId
        );
        return {
          id,
          languages,
        };
      });
      setPins(pinsList);
      const langsList = res.data.map((data) => {
        const { id, name } = data;
        let pinsCount = 0;
        pinsList.forEach((pin) => {
          if (pin.languages.includes(id)) {
            pinsCount++;
          }
        });
        let categoriesCount = 0;

        categoriesList.forEach((category) => {
          if (category.languages.includes(id)) {
            categoriesCount++;
          }
        });
        return {
          id,
          name,
          pinsCount,
          categoriesCount,
        };
      });

      setLangs(langsList);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleAdd(data) {
    const { id, name } = data;
    let pinsCount = 0;
    pins.forEach((pin) => {
      if (pin.languages.includes(id)) {
        pinsCount++;
      }
    });
    let categoriesCount = 0;
    categories.forEach((category) => {
      if (category.languages.includes(id)) {
        categoriesCount++;
      }
    });
    setLangs([
      ...langs,
      {
        id,
        name,
        pinsCount,
        categoriesCount,
      },
    ]);
  }

  function handleRemove(id: string) {
    const newList = langs.filter((language) => language.id !== id);

    setLangs(newList);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Container component="main" maxWidth="md">
            <EnhancedTableToolbar
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              length={langs.length}
              page={page}
              rowsPerPage={rowsPerPage}
              title="Linguas"
            />
            <TableContainer>
              {langs !== null && !loading ? (
                <Table size="small">
                  <EnhancedTableHead
                    titles={[
                      'ID',
                      'Nome',
                      'Pontos sem tradução',
                      '',
                      'Categorias sem tradução',
                      ' ',
                    ]}
                  />
                  <TableBody>
                    {langs
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((lang, index) => (
                        <LanguageListItem
                          key={index}
                          index={index}
                          lang={lang}
                          missingPins={langs[0].pinsCount - lang.pinsCount}
                          missingCategories={
                            langs[0].categoriesCount - lang.categoriesCount
                          }
                          onRemove={handleRemove}
                        />
                      ))}
                  </TableBody>
                </Table>
              ) : (
                <CircularProgress />
              )}
            </TableContainer>
            {localStorage.token && canIAddLanguages(localStorage.token) ? (
              <AddButton onAdd={handleAdd} />
            ) : (
              <div />
            )}
          </Container>
        </Paper>
      </Grid>
    </Container>
  );
};

export default React.memo(Languages);
