import {
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../../context/auth/authContext';
import { useLocation } from 'react-router-dom';
import PinListItem from './PinListItem';
import EnhancedTableToolbar from '../../../layout/EnhancedTableToolbar';
import EnhancedTableHead from '../../../layout/EnhancedTableHead';
import axios from 'axios';

export interface pinData {
  id: string;
  description: string;
  title: string;
}

const LanguagePinsList = () => {
  const classes = useStyles();
  const location = useLocation();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [ptPins, setPtPins] = useState<pinData[]>([]);
  const [pins, setPins] = useState<pinData[]>([]);
  const languageId = location.state.languageId;

  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPins();
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPins = async () => {
    try {
      setLoading(true);
      const ptRes = await axios.get(`/pins/lang/pt`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const newPtList = ptRes.data.map((pinData, index) => {
        const { id, pinLanguages } = pinData;
        const { title, description } = pinLanguages[0];
        return {
          title,
          id,
          description,
        };
      });

      const res = await axios.get(`/pins/lang/${languageId}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const newList = res.data.map((pinData, index) => {
        const { id, pinLanguages } = pinData;
        let title = '';
        let description = '';

        if (pinLanguages.length > 0) {
          title = pinLanguages[0].title;
          description = pinLanguages[0].description;
        }

        return {
          title,
          id,
          description,
        };
      });
      setPtPins(newPtList);
      setPins(newList);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleEdit(id: string, title: string, description: string) {
    setPins((pins) => {
      const list = pins.map((pin) => {
        if (pin.id === id) {
          pin.description = description;
          pin.title = title;
        }
        return pin;
      });
      return list;
    });
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Container component="main" maxWidth="md">
            <EnhancedTableToolbar
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              length={pins.length}
              page={page}
              rowsPerPage={rowsPerPage}
              title={'Pontos - ' + languageId}
            />
            <TableContainer>
              {pins !== null && !loading ? (
                <Table size="small">
                  <EnhancedTableHead titles={['Titulo', 'Descrição']} />
                  <TableBody>
                    {pins
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((pin, index) => {
                        let ptTitle;
                        let ptDescription;
                        ptPins.forEach((ptPin) => {
                          if (ptPin.id === pin.id) {
                            ptTitle = ptPin.title;
                            ptDescription = ptPin.description;
                            return;
                          }
                        });

                        return (
                          <PinListItem
                            key={pin.id}
                            pin={pin}
                            ptTitle={ptTitle}
                            ptDescription={ptDescription}
                            onEdit={handleEdit}
                            languageId={languageId}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              ) : (
                <CircularProgress />
              )}
            </TableContainer>
          </Container>
        </Paper>
      </Grid>
    </Container>
  );
};

export default React.memo(LanguagePinsList);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  FloatBtn: {
    marginTop: theme.spacing(4),
  },
  space: {
    margin: theme.spacing(0, 5),
  },
}));
