import axios from 'axios';

export const submit = async (
  setLoading,
  { id, name },
  enqueueSnackbar,
  onAdd
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.token,
    },
  };
  try {
    setLoading(true);
    const res = await axios.post('/langs/', { id, name }, config);
    enqueueSnackbar(`Lingua adicionada com sucesso!`, {
      variant: 'success',
      autoHideDuration: 2000,
    });
    onAdd(res.data);
  } catch (error) {
    //error message
    console.log(error);
    enqueueSnackbar(error, {
      variant: 'warning',
    });
  } finally {
    setLoading(false);
  }
};
