import {
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../../context/auth/authContext';
import { useLocation } from 'react-router-dom';
import CategoryListItem from './CategoryListItem';
import EnhancedTableToolbar from '../../../layout/EnhancedTableToolbar';
import EnhancedTableHead from '../../../layout/EnhancedTableHead';
import axios from 'axios';

export interface categoryData {
  id: string;
  name: string;
}

const CategoryLanguagesList = () => {
  const classes = useStyles();
  const location = useLocation();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [ptCategories, setPtCategories] = useState<categoryData[]>([]);
  const [categories, setCategories] = useState<categoryData[]>([]);
  const languageId = location.state.languageId;

  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCategories = async () => {
    try {
      setLoading(true);
      const ptRes = await axios.get(`/categories/lang/pt`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const newPtList = ptRes.data.map((pinData, index) => {
        const { id, categoryLanguages } = pinData;
        const { name } = categoryLanguages[0];
        return {
          name,
          id,
        };
      });

      const res = await axios.get(`/categories/lang/${languageId}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      const newList = res.data.map((pinData, index) => {
        const { id, categoryLanguages } = pinData;
        let name = '';
        if (categoryLanguages.length > 0) {
          name = categoryLanguages[0].name;
        }

        return {
          name,
          id,
        };
      });
      setPtCategories(newPtList);
      setCategories(newList);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleEdit(id: string, name: string) {
    setCategories((categories) => {
      const list = categories.map((category) => {
        if (category.id === id) {
          category.name = name;
        }
        return category;
      });
      return list;
    });
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Container component="main" maxWidth="md">
            <EnhancedTableToolbar
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              length={categories.length}
              page={page}
              rowsPerPage={rowsPerPage}
              title={'Categorias - ' + languageId}
            />
            {/* <Container component="main" maxWidth="xs"> */}

            <TableContainer>
              {categories !== null && !loading ? (
                <Table size="small">
                  <EnhancedTableHead titles={['Nome']} />
                  <TableBody>
                    {categories
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((category, index) => {
                        let ptName;
                        ptCategories.forEach((ptCategory) => {
                          if (ptCategory.id === category.id) {
                            ptName = ptCategory.name;
                            return;
                          }
                        });

                        return (
                          <CategoryListItem
                            key={category.id}
                            category={category}
                            ptName={ptName}
                            onEdit={handleEdit}
                            languageId={languageId}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              ) : (
                <CircularProgress />
              )}
            </TableContainer>
            {/* </Container> */}
          </Container>
        </Paper>
      </Grid>
    </Container>
  );
};

export default React.memo(CategoryLanguagesList);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  FloatBtn: {
    marginTop: theme.spacing(4),
  },
  space: {
    margin: theme.spacing(0, 5),
  },
}));
