import { createStyles, makeStyles, TableRow, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import { useSnackbar } from 'notistack';
import EditButton from './edit/EditButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    space: {
      margin: theme.spacing(0, 5),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    item: {
      width: '100%',
    },
  })
);

const CategoryListItem = ({ category, ptName, onEdit, languageId }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { id, name } = category;

  return (
    <TableRow
      key={id}
      hover
      style={{ backgroundColor: name ? '#fff' : '#FFB6C1' }}
    >
      <TableCell>{name ? name : ptName}</TableCell>
      <TableCell>
        <EditButton
          category={category}
          ptName={ptName}
          languageId={languageId}
          onEdit={onEdit}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(CategoryListItem);
