import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import { UserDto } from '@cmmv-turismo/data';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Title from '../../../layout/Title';
import { Box, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useStyles } from './addUsersStyles';
import axios from 'axios';
import SelectRole from '../../../layout/users/SelectRole';
import { submit } from './submit';

const AddUser = ({ onAdd }) => {
  // console.log("I'm AddUser");
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [user, setUser] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    role: '',
  });
  const { username, password } = user;
  const [loading, setLoading] = useState(false);

  const onChange = (e: { target: { name: string; value: string } }) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleChangeRole = (event: React.ChangeEvent<{ value: unknown }>) =>
    setUser({ ...user, role: event.target.value as string });

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (user.password === user.confirmPassword)
      submit(setLoading, user, enqueueSnackbar, onAdd);
    else
      enqueueSnackbar('Password e confirmação de Password não correspondem', {
        variant: 'warning',
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Title>Adicionar Utilizador</Title>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            onChange={onChange}
            id="username"
            label="Email"
            name="username"
            autoComplete="email"
            autoFocus
          />
          <SelectRole onChange={handleChangeRole} role={user.role} />

          <TextField
            margin="normal"
            required
            fullWidth
            onChange={onChange}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <TextField
            margin="normal"
            required
            fullWidth
            onChange={onChange}
            name="confirmPassword"
            label="Confirmar Password"
            type="password"
            id="confirmPassword"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            variant="contained"
            disabled={
              !user.username ||
              !user.password ||
              !user.confirmPassword ||
              loading
            }
            color="primary"
            className={classes.submit}
          >
            Adicionar
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </form>
      </div>
    </Container>
  );
};

export default React.memo(AddUser);
