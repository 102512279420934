import { Container, Grid, makeStyles, Paper } from '@material-ui/core';
import { LatLng } from 'leaflet';
import React, { useEffect, useState } from 'react';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet';
import LocationMarker from '../../../utils/LocationMarker';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  FloatBtn: {
    marginTop: theme.spacing(4),
  },
  input: {
    display: 'none',
  },
  leaflet_container: {
    width: '100%',
    height: '300px',
  },
}));

const Tests = () => {
  const classes = useStyles();

  const [position, setPosition] = useState<LatLng>(null);
  useEffect(() => {
    console.log(position);
  }, [position]);

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Container component="main" maxWidth="sm">
              <MapContainer
                center={{ lat: 51.505, lng: -0.09 }}
                zoom={13}
                className={classes.leaflet_container}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                <LocationMarker position={position} setPosition={setPosition} />
              </MapContainer>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(Tests);
