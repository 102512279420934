import { IconButton } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import EditButton from './edit/EditButton';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { getMyUsername } from '../../../utils/parseJWT';

const ListItem = ({ user, onRemove }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleDeleteClick = async (id: number) => {
    // console.log(id);
    try {
      await axios.delete(`/users/${id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });
      enqueueSnackbar(`Utilizador removido com sucesso`);
      onRemove(id);
    } catch (error) {
      if (error.response.data.message === 'User not found')
        enqueueSnackbar('Utilizador não existe');
      else {
        enqueueSnackbar(error.response.data.message);
        console.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      <TableCell>{user.username}</TableCell>
      <TableCell>{user.role}</TableCell>
      <TableCell>
        {user.username !== getMyUsername(localStorage.token) ? (
          <>
            <EditButton username={user.username} />
            <IconButton
              aria-label="delete"
              onClick={() => handleDeleteClick(user.id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ) : null}
      </TableCell>
    </>
  );
};

export default React.memo(ListItem);
