import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PinDropIcon from '@material-ui/icons/PinDrop';
import PeopleIcon from '@material-ui/icons/People';
import TranslateIcon from '@material-ui/icons/Translate';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import useStyles from './leftDrawerStyles';
import { Box } from '@material-ui/core';
import AuthContext from '../../context/auth/authContext';
import parseJwt, { canIManageUser } from '../../utils/parseJWT';

const LeftDrawer = () => {
  const authContext = useContext(AuthContext);
  // console.log("I'm LeftDrawer");

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    url: string
  ) => {
    history.push(url);
    setSelectedIndex(index);
  };

  const handleLogout = () => {
    authContext.logout();
  };

  const menuList: { title: string; url: string; icon: JSX.Element }[] = [];

  if (localStorage.token && canIManageUser(localStorage.token)) {
    menuList.push({
      title: 'Gestão de utilizadores',
      url: '/users',
      icon: <PeopleIcon />,
    });
  }

  menuList.push(
    { title: 'Categorias', url: '/categories', icon: <AccountBalanceIcon /> },
    { title: 'Pontos', url: '/pins', icon: <PinDropIcon /> },
    { title: 'Gestão de Linguas', url: '/languages', icon: <TranslateIcon /> }
  );

  return (
    <div className={classes.root}>
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {localStorage.token ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <Typography variant="h6" noWrap>
            cmmv-turismo backoffice
          </Typography>
          <div className={classes.toolbarButtons}>
            <Button color="inherit" onClick={() => handleLogout()}>
              Sair
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {menuList.map(({ title, icon, url }, index) => (
            <ListItem
              button
              key={title}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index, url)}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default React.memo(LeftDrawer);
