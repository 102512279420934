import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import { canIEditPin } from '../../utils/parseJWT';

const EditPinRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading } = authContext;
  if (!localStorage.token) {
    return <Route {...rest} render={(props) => <Redirect to="/login" />} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        (!isAuthenticated || !canIEditPin(localStorage.token)) && !loading ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default EditPinRoute;
