import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

interface EnhancedTableProps {
  titles: string[];
  // numSelected: number;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { titles /* , onSelectAllClick, numSelected, rowCount */ } = props;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {titles.map((title) => (
          <TableCell key={title}>
            <b>{title}</b>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default React.memo(EnhancedTableHead);
