import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '50%',
      marginTop: 3,
    },
  })
);

const SelectRole = ({ onChange, role }) => {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Função</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={role}
          onChange={onChange}
        >
          <MenuItem value={'Administrator'}>Administrador</MenuItem>
          <MenuItem value={'ContentManager'}>Gestor de conteudo</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(SelectRole);
