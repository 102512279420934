import React from 'react';
import SelectRole from '../../../layout/users/SelectRole';

const EditRole = ({ user, setUser }) => {
  const handleChangeRole = (event: React.ChangeEvent<{ value: unknown }>) =>
    setUser({ ...user, role: event.target.value as string });

  return (
    <div>
      <SelectRole onChange={handleChangeRole} role={user.role} />
    </div>
  );
};

export default React.memo(EditRole);
