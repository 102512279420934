import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Fab, IconButton, makeStyles } from '@material-ui/core';
import EditCategory from './EditCategory';
import EditIcon from '@material-ui/icons/Edit';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  FloatBtn: {
    marginTop: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const EditButton = ({ category, onEdit }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={() => handleClickOpen()}>
        <EditIcon />
      </IconButton>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <MuiDialogTitle disableTypography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent>
          <EditCategory category={category} onEdit={onEdit} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(EditButton);
